import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Portal } from '../index';
import { checkNodeIsSelected, generateId, getUsedNodes, saveEditorSelection, restoreEditorSelection, replaceSelectedText, dropSelections, } from './utils';
import { WysiwygControls } from './WysiwygControls';
import { UrlEditor } from './UrlEditor';
import { PseudoSelection } from './PseudoSelection';
import './Wysiwyg.scss';
export const WysiwygEditor = React.memo(React.forwardRef(({ defaultText = '', label, className = '', onChange, maxLength, onKeyDown, onBlur = () => { }, autoFocus, showControls = true, triggerChangeOnControls, onFocus: onFocusProp, editDisabled, }, ref) => {
    const htmlText = useRef(defaultText);
    const editor = useRef(null);
    const editorContainerRef = useRef(null);
    const justSavedLink = useRef(false);
    const id = useRef(generateId());
    const position = useRef({
        left: 0,
        top: 0,
        height: 0,
        width: 0,
    });
    const textInUrl = useRef('');
    const urlText = useRef('');
    const savedRanges = useRef([]);
    const [selectedNodes, setSelected] = useState([]);
    const [showUrlEditor, setUrlEditor] = useState(false);
    const onChangeHandler = useCallback(() => {
        var _a, _b;
        if (maxLength &&
            ((_a = editor.current) === null || _a === void 0 ? void 0 : _a.textContent) &&
            editor.current.firstChild &&
            editor.current.textContent.length > maxLength) {
            editor.current.textContent = editor.current.innerText.substring(0, maxLength);
            const range = new Range();
            range.setStart(editor.current.firstChild, maxLength);
            range.collapse(true);
            restoreEditorSelection([range]);
            return;
        }
        const usedNodes = getUsedNodes(editor.current);
        if (usedNodes.join('') !== selectedNodes.join('')) {
            setSelected(usedNodes);
        }
        const nodes = (_b = editor.current) === null || _b === void 0 ? void 0 : _b.querySelectorAll('a');
        if (nodes) {
            const arrayNodes = Array.from(nodes);
            arrayNodes.forEach(nodeA => {
                if (!nodeA.target) {
                    nodeA.target = '_blank';
                }
            });
        }
        onChange && editor.current && onChange(editor.current.innerHTML);
    }, [selectedNodes, onChange]);
    const makeElement = useCallback((e) => {
        var _a, _b;
        const target = e.target;
        const role = (_a = target.dataset) === null || _a === void 0 ? void 0 : _a.role;
        if (editor.current && role) {
            let ranges = saveEditorSelection((_b = editor.current) === null || _b === void 0 ? void 0 : _b.parentElement);
            if (!ranges || ranges.length === 0)
                return;
            switch (role) {
                case 'pre': {
                    let range = ranges ? ranges[0] : null;
                    if (range) {
                        let text = range.toString();
                        if (text) {
                            const isAlreadyPre = !!checkNodeIsSelected(selectedNodes, 'PRE');
                            range.deleteContents();
                            if (isAlreadyPre) {
                                const replacedRange = document.createRange();
                                let parent = range.commonAncestorContainer.parentNode;
                                while (parent && parent !== editor.current && parent.nodeName !== 'PRE') {
                                    parent = parent.parentNode;
                                }
                                if (parent) {
                                    replacedRange.selectNode(parent);
                                    replaceSelectedText(replacedRange, text);
                                }
                            }
                            else {
                                let pre = document.createElement('pre');
                                pre.appendChild(document.createTextNode(text));
                                range.insertNode(pre);
                            }
                        }
                    }
                    onChangeHandler();
                    break;
                }
                case 'url': {
                    if (ranges && editorContainerRef.current) {
                        textInUrl.current = ranges[0].toString();
                        urlText.current = '';
                        if (checkNodeIsSelected(selectedNodes, 'A')) {
                            let foundLink = ranges[0].commonAncestorContainer.parentNode;
                            while (foundLink && foundLink.nodeName !== 'A') {
                                foundLink = foundLink.parentNode;
                            }
                            if (foundLink) {
                                let range = document.createRange();
                                range.selectNode(foundLink);
                                ranges = [range];
                                urlText.current = foundLink.href;
                                textInUrl.current = foundLink.innerText;
                            }
                            if (!ranges)
                                return;
                        }
                        savedRanges.current = ranges;
                        const caretPosition = ranges[0].getBoundingClientRect();
                        position.current = {
                            left: caretPosition.left,
                            top: caretPosition.top,
                            height: caretPosition.height,
                            width: caretPosition.width,
                        };
                        setUrlEditor(true);
                    }
                    break;
                }
                default: {
                    document.execCommand(role, false);
                    triggerChangeOnControls && onChangeHandler();
                    break;
                }
            }
            const event = new Event('input');
            editor.current.dispatchEvent(event);
            editor.current.focus();
            const usedNodes = getUsedNodes(editor.current);
            if (usedNodes.join('') !== selectedNodes.join('')) {
                setSelected(usedNodes);
            }
        }
    }, [onChangeHandler, selectedNodes, triggerChangeOnControls]);
    const checkClick = (event) => {
        event.stopPropagation();
        //READ this is needed because of action on url click. it just need to be function
    };
    const checkMouseKeys = () => {
        const usedNodes = getUsedNodes(editor.current);
        if (usedNodes.join('') !== selectedNodes.join('')) {
            setSelected(usedNodes);
        }
    };
    const nodeIsSelected = useCallback((nodeName) => {
        return checkNodeIsSelected(selectedNodes, nodeName);
    }, [selectedNodes]);
    const checkKeys = useCallback((e) => {
        const usedNodes = getUsedNodes(editor.current);
        if (usedNodes.join('') !== selectedNodes.join('')) {
            setSelected(usedNodes);
        }
        if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            return;
        }
        if (justSavedLink.current) {
            justSavedLink.current = false;
            e.preventDefault();
            return false;
        }
        if (e.type === 'keydown') {
            onKeyDown && onKeyDown(e);
        }
        if (e.key === 'Enter' &&
            e.type === 'keydown' &&
            !(nodeIsSelected('UL') || nodeIsSelected('PRE') || nodeIsSelected('OL'))) {
            e.preventDefault();
            document.execCommand('insertHTML', false, '<br/><br/>');
            return false;
        }
        if (e.key === 'Enter' && e.type === 'keydown' && !e.shiftKey && nodeIsSelected('PRE')) {
            e.preventDefault();
            document.execCommand('insertHTML', false, '<br/>');
            return false;
        }
    }, [selectedNodes, nodeIsSelected, onKeyDown]);
    const saveUrl = useCallback((link, text) => {
        if (savedRanges.current) {
            setUrlEditor(false);
            const replacedSelection = replaceSelectedText(savedRanges.current[0], text);
            restoreEditorSelection([replacedSelection]);
            justSavedLink.current = true;
            if (link) {
                let linkToSave = link.startsWith('http://') || link.startsWith('https://') ? link : '//' + link;
                document.execCommand('CreateLink', false, linkToSave);
            }
            dropSelections([replacedSelection]);
            onChangeHandler();
        }
    }, [onChangeHandler]);
    const onFocus = useCallback(event => {
        const usedNodes = getUsedNodes(editor.current);
        if (usedNodes.join('') !== selectedNodes.join('')) {
            setSelected(usedNodes);
        }
        onFocusProp && onFocusProp(event);
    }, [selectedNodes, onFocusProp]);
    const pasteHandler = useCallback((event) => {
        event.preventDefault();
        const clip = event.clipboardData.getData('text/plain').replace(/\n/g, '<br/>');
        let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
        let urlq = clip.match(urlRegex);
        if (urlq) {
            document.execCommand('CreateLink', false, clip);
        }
        else {
            document.execCommand('insertHTML', false, clip);
        }
    }, []);
    useEffect(() => {
        const editorScope = editor.current;
        editorScope === null || editorScope === void 0 ? void 0 : editorScope.addEventListener('input', onChangeHandler);
        return () => editorScope === null || editorScope === void 0 ? void 0 : editorScope.removeEventListener('input', onChangeHandler);
    }, [onChangeHandler]);
    useEffect(() => {
        if (!ref)
            return;
        //@ts-ignore
        ref.current = editor.current;
    }, [ref]);
    useLayoutEffect(() => {
        var _a;
        if (autoFocus)
            (_a = editor.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [autoFocus]);
    const closeUrlEditor = useCallback(() => setUrlEditor(false), []);
    return (_jsxs("div", { className: `editorContainer ${className}`, ref: editorContainerRef, children: [_jsx("div", { "data-test-id": 'WysiwygEditor:contenteditable', className: classNames('editor', { filled_editor: htmlText.current }), id: id.current, ref: editor, contentEditable: !editDisabled, dangerouslySetInnerHTML: { __html: htmlText.current }, onKeyDown: checkKeys, onKeyUp: checkKeys, onMouseDown: checkMouseKeys, onMouseUp: checkMouseKeys, onFocus: onFocus, onClick: checkClick, onPaste: pasteHandler, onBlur: onBlur }), label && (_jsx("label", { onClick: () => { var _a; return (_a = document === null || document === void 0 ? void 0 : document.getElementById(id.current)) === null || _a === void 0 ? void 0 : _a.focus(); }, htmlFor: id.current, children: label })), showControls && (_jsx(WysiwygControls, { checkNodeIsSelected: nodeIsSelected, makeElement: makeElement, disabled: editDisabled })), showUrlEditor && (_jsx(Portal, { targetNodeSelector: 'body', children: _jsx(UrlEditor, { text: textInUrl.current, url: urlText.current, position: position.current, close: closeUrlEditor, save: saveUrl }) })), showUrlEditor && _jsx(PseudoSelection, { position: position.current })] }));
}));
