import { useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import usePromiseProcessing from '@just-ai/just-ui/dist/utils/usePromiseProcessing';
import { ProjectSkillDataUpdate, ProjectSkillRead } from '@just-ai/api/dist/generated/Editorbe';

import { useAppDispatch } from 'storeHooks';
import { getUserLanguage } from 'pipes/pureFunctions';

import { loadProjects } from 'actions/botprojects.actions';
import { useAppContext } from 'contexts/AppContext';

import { RealSkill } from '../types';
import { isChatGptEnabled } from '../access';

function filterSkills(skills: ProjectSkillRead[]): ProjectSkillRead[] {
  const chatGPTFeatureEnabled = isChatGptEnabled();
  return skills.filter(skill => {
    if (chatGPTFeatureEnabled && skill.name === RealSkill.FAQ) return false;
    if (!chatGPTFeatureEnabled && skill.name === RealSkill.FAQ_WITH_CHAT_GPT) return false;
    return true;
  });
}

export function useProjectSkill(projectShortName: string, shouldRequest = true) {
  const { projectSkillsService } = useAppContext();

  const [getListProjectSkillsState, getListProjectSkillsApi] = usePromiseProcessing(
    (language: string) => projectSkillsService.getListProjectSkills(language).then(filterSkills),
    { deps: [projectSkillsService] }
  );

  const [getProjectState, getProjectApi] = usePromiseProcessing(
    () => projectSkillsService.getProjectSkill(projectShortName),
    { deps: [projectSkillsService, projectShortName] }
  );
  const [updateProjectSkillState, updateProjectSkillApi] = usePromiseProcessing(
    (projectSkillData: ProjectSkillDataUpdate, commit?: boolean) => {
      return projectSkillsService.updateProjectSkill(projectShortName, projectSkillData, commit);
    },
    { deps: [projectSkillsService] }
  );

  useEffect(() => {
    if (!shouldRequest) return;
    // noinspection JSIgnoredPromiseFromCall
    getProjectApi();
  }, [getProjectApi, shouldRequest]);

  const skillProjectLang = useMemo(() => {
    if (!getProjectState.result) return;
    const projectSkillLang = getProjectState.result.skills?.[0]?.language;
    const lang = getUserLanguage();
    let resultLang = projectSkillLang || lang;
    if (resultLang === 'eng') {
      resultLang = 'en';
    }
    return resultLang;
  }, [getProjectState.result]);

  useEffect(() => {
    if (!skillProjectLang || !shouldRequest) return;
    // noinspection JSIgnoredPromiseFromCall
    getListProjectSkillsApi(skillProjectLang);
  }, [getListProjectSkillsApi, shouldRequest, skillProjectLang]);

  return {
    registeredSkills: getListProjectSkillsState.result,
    skillProject: getProjectState.result,
    updateProjectSkill: updateProjectSkillApi,
    loading: getProjectState.loading || updateProjectSkillState.loading || getListProjectSkillsState.loading,
    skillProjectLang,
  };
}

export function useCreateProjectSkill() {
  const dispatch = useAppDispatch();
  const boundActionCreators = useMemo(() => bindActionCreators({ loadProjects }, dispatch), [dispatch]);
  const { projectSkillsService, botProjectService } = useAppContext();
  return usePromiseProcessing(
    async (projectName: string) => {
      const newProject = await projectSkillsService.createProject(
        {
          name: projectName,
        },
        getUserLanguage()
      );
      // noinspection ES6RedundantAwait
      await boundActionCreators.loadProjects(() => botProjectService.getProjects());
      return newProject;
    },
    { deps: [projectSkillsService, botProjectService] }
  );
}
